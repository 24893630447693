const possiblePermissions = [
  "accelerometer",
  "accessibility",
  "ambient-light-sensor",
  "camera",
  "clipboard-read",
  "clipboard-write",
  "geolocation",
  "background-sync",
  "magnetometer",
  "microphone",
  "midi",
  "notifications",
  "payment-handler",
  "persistent-storage",
  "push",
];

function getPermission(name: string) {
  if (!navigator.permissions) {
    return Promise.resolve({ name, present: false });
  }
  return navigator.permissions.query({ name: name as PermissionName }).then(
    (_) => ({ name, present: true }),
    (_) => ({ name, present: false })
  );
}

export function getPermissions() {
  const permissions = possiblePermissions.map((name) => getPermission(name));
  return Promise.all(permissions);
}
