function doesMatch(type: string, value: string) {
  return matchMedia(`(${type}: ${value})`).matches;
}

const invertedColors = "inverted-colors";

// taken from https://github.com/fingerprintjs/fingerprintjs/blob/master/src/sources/inverted_colors.ts
function getColorsInverted(): number {
  if (doesMatch(invertedColors, "inverted")) {
    return 2;
  }
  if (doesMatch(invertedColors, "none")) {
    return 1;
  }
  return 0;
}

const forcedColors = "forced-colors";

// taken from https://github.com/fingerprintjs/fingerprintjs/blob/master/src/sources/forced_colors.ts
function getColorsForced(): number {
  if (doesMatch(forcedColors, "active")) {
    return 2;
  }
  if (doesMatch(forcedColors, "none")) {
    return 1;
  }
  return 0;
}

type ColorGamut = "srgb" | "p3" | "rec2020";

// rec2020 includes p3 and p3 includes srgb
const gamuts: ColorGamut[] = ["srgb", "p3", "rec2020"];
const colorGamut = "color-gamut";

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/color-gamut
 */
function getColorGamut(): number {
  for (let i = 0; i < gamuts.length; i++) {
    const gamut = gamuts[i];
    if (matchMedia(`(${colorGamut}: ${gamut})`).matches) {
      return i + 1;
    }
  }
  return 0;
}

const dynamicRange = "dynamic-range";
function getHDR(): number {
  if (doesMatch(dynamicRange, "high")) {
    return 2;
  }
  if (doesMatch(dynamicRange, "standard")) {
    return 1;
  }
  return 0;
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
const prefersReducedMotion = "prefers-reduced-motion";
function getMotionReduced(): number {
  if (doesMatch(prefersReducedMotion, "reduce")) {
    return 2;
  }
  if (doesMatch(prefersReducedMotion, "no-preference")) {
    return 1;
  }
  return 0;
}

export function getMediaMatches(): number {
  return (
    getMotionReduced() * 10000 +
    getHDR() * 1000 +
    getColorGamut() * 100 +
    getColorsInverted() * 10 +
    getColorsForced()
  );
}

export function isLandscape() {
  return doesMatch("orientation", "landscape");
}
