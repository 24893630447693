import { createFingerprint } from "./fingerprint";
import { hash } from "./hash";
import { isPromiseLike } from "./typeGuards";
import { ping1 } from "./visitor-ping";
import { getVisitorId } from "./visitor-storage";

const tryOrEmpty =
  <T extends Array<any>, U>(fn: (...args: T) => U) =>
  (...args: T): U => {
    try {
      const result = fn(...args);

      if (isPromiseLike(result)) {
        return result.then(
          (v) => v,
          (error) => `${error}`
        ) as any as U;
      }

      return result;
    } catch (e) {
      return `${e}` as any as U;
    }
  };

export async function ping() {
  let start = Date.now();

  const fp = await createFingerprint(tryOrEmpty, hash);
  const visitorId = getVisitorId();
  const elapsed = Date.now() - start;

  ping1({
    browserHash: fp.browserHash,
    visitorId,
    elapsed,
  });

  document.addEventListener("visibilitychange", () => {
    const type = document.hidden ? "unload" : "review";
    if (type === "review") {
      start = Date.now();
    }
    const elapsed = type == "unload" ? Date.now() - start : 0;
    ping1({
      browserHash: fp.browserHash,
      visitorId,
      elapsed,
      type,
    });
  });

  const done = Date.now();
  console.log(`Created fingerprint in ${done - start}ms`);
}
