export function toInt(value: unknown, replaceNaN: number): number {
  const possibleNumber = parseInt(value as string);
  return isNaN(possibleNumber) ? replaceNaN : possibleNumber;
}

export function toFloat(value: unknown, replaceNan: number): number {
  const possibleFloat = parseFloat(value as string);
  return isNaN(possibleFloat) ? replaceNan : possibleFloat;
}

function fireOnce(cb: () => void) {
  let fired = false;

  return () => {
    if (fired) {
      return;
    }
    fired = true;
    cb();
  };
}

function defer(cb: () => void) {
  cb = fireOnce(cb);
  const visible = document.visibilityState !== "hidden";

  const fun = visible
    ? window.requestAnimationFrame || window.setTimeout
    : window.setTimeout;

  if (visible) {
    const fireCallbackWhenHidden = () => {
      if (document.visibilityState !== "hidden") {
        return;
      }
      cb();
    };
    document.addEventListener("visibilitychange", fireCallbackWhenHidden);
  }

  fun(cb);
}

export function rafOrTimeout<T>(fn: () => T) {
  return new Promise<T>((resolve) => {
    defer(() => resolve(fn()));
  });
}
