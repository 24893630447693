import { getEtag } from "./etag";
import { postJson } from "./fetch";
import { computePath } from "./path";

export function isError(e: any): e is Error {
  return e && e.message && e.name;
}

export function logError(error: any) {
  let message;

  if (isError(error)) {
    message = `${error.name}: ${error.message}`;
    const stack = error.stack;

    if (stack) {
      message += `\n${stack}`;
    }
  } else {
    message = error.message || JSON.stringify(error);
  }

  return log("ERROR", message);
}

export function log(level: "INFO" | "ERROR", message: string) {
  return postJson(computePath("/log/hash"), {
    level,
    message,
    etag: getEtag(),
  });
}
