import { createVerifier } from "./hash";
import { computePath } from "./path";
import { logError } from "./remote-logging";

function addImage(...segments: string[]) {
  const png: HTMLImageElement = document.createElement("img");
  png.src = computePath(...segments);
  png.style.cssText = "position: absolute; top: -100px";
  document.body.appendChild(png);
}

function cacheBuster() {
  return Math.floor(Math.random() * 100000000);
}

export type PingPayload = {
  browserHash?: string | number;
  visitorId?: string | number;
  elapsed?: number;
  type?: "load" | "unload" | "review";
};

export function ping1({ browserHash, visitorId, elapsed, type }: PingPayload) {
  type = type || "load";

  try {
    const baseName = cacheBuster();
    browserHash = browserHash || "-";
    visitorId = visitorId || "-";
    elapsed = elapsed || 0;

    const verifier = createVerifier({ browserHash, visitorId, baseName, type });

    addImage(
      "/pxl",
      type !== "load" ? type : "",
      `${browserHash}/${visitorId}/${elapsed}/${baseName}-${verifier}.png`
    );
  } catch (error: any) {
    logError(error);
  }
}
