const possibleAudioTypes = [
  "audio/3gpp",
  "audio/3gpp2",
  "audio/AMR-NB",
  "audio/AMR-WB",
  "audio/GSM",
  "audio/aac",
  "audio/basic",
  "audio/flac",
  "audio/midi",
  "audio/mpeg",
  'audio/mp4; codecs="mp4a.40.2"',
  'audio/mp4; codecs="ac-3"',
  'audio/mp4; codecs="ec-3"',
  'audio/ogg; codecs="flac"',
  'audio/ogg; codecs="vorbis"',
  'audio/ogg; codecs="opus"',
  'audio/wav; codecs="1"',
  'audio/webm; codecs="vorbis"',
  'audio/webm; codecs="opus"',
  "audio/x-aiff",
  "audio/x-mpegurl",
].sort();
const possibleVideoTypes = [
  'video/mp4; codecs="flac"',
  'video/mp4; codecs="H.264, mp3"',
  'video/mp4; codecs="H.264, aac"',
  'video/mpeg; codec="H.264"',
  'video/ogg; codecs="theora"',
  'video/ogg; codecs="opus"',
  'video/webm; codecs="vp9, opus"',
  'video/webm; codecs="vp8, vorbis"',
].sort();

type HTMLCanPlayElement = HTMLAudioElement | HTMLVideoElement;

const elements: { audio?: HTMLCanPlayElement; video?: HTMLCanPlayElement } = {};

function canPlay(source: "audio" | "video", type: string) {
  const el = elements[source] || document.createElement(source);
  elements[source] = el;
  return el && el.canPlayType && el.canPlayType(type);
}

function toSupportDescription(source: "audio" | "video", type: string) {
  let supported;
  if (MediaSource.isTypeSupported) {
    supported = MediaSource.isTypeSupported(type);
  }

  const playable = canPlay(source, type);

  return { name: type, support: supported, playable };
}

export function getSupportedAudioTypes() {
  return possibleAudioTypes
    .map((f) => toSupportDescription("audio", f))
    .filter((f) => !!f);
}

export function getSupportedVideoTypes() {
  return possibleVideoTypes
    .map((f) => toSupportDescription("video", f))
    .filter((f) => !!f);
}
