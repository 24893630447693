function isNative(obj: any) {
  return (
    obj &&
    typeof obj === "function" &&
    obj.toString().indexOf("[native code]") > 0
  );
}

export function getNativeNavigatorFunctions() {
  const props = [];

  for (const property in navigator) {
    const value = (navigator as any)[property];

    if (isNative(value)) {
      props.push(property);
    }
  }

  return props.sort().join(",");
}
