const langProperties = [
  "language",
  "browserLanguage",
  "systemLanguage",
  "userLanguage",
];

export function getLanguages(): string {
  const languages = navigator.languages || [];
  const additionalLanguages = langProperties
    .map((prop) => (navigator as any)[prop] || "")
    .filter(Boolean);
  return languages.concat(additionalLanguages).join(",");
}
