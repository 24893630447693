// https://github.com/fingerprintjs/fingerprintjs/blob/v3.3.2/src/sources/math.ts

const noop = () => 0;

// Native operations
const acos = Math.acos || noop;
const acosh = Math.acosh || noop;
const asin = Math.asin || noop;
const asinh = Math.asinh || noop;
const atanh = Math.atanh || noop;
const atan = Math.atan || noop;
const sin = Math.sin || noop;
const sinh = Math.sinh || noop;
const cos = Math.cos || noop;
const cosh = Math.cosh || noop;
const tan = Math.tan || noop;
const tanh = Math.tanh || noop;
const exp = Math.exp || noop;
const expm1 = Math.expm1 || noop;
const log1p = Math.log1p || noop;

// Operation polyfills
const acoshPf = (value: number) =>
  Math.log(value + Math.sqrt(value * value - 1));
const asinhPf = (value: number) =>
  Math.log(value + Math.sqrt(value * value + 1));
const atanhPf = (value: number) => Math.log((1 + value) / (1 - value)) / 2;
const sinhPf = (value: number) => Math.exp(value) - 1 / Math.exp(value) / 2;
const coshPf = (value: number) => (Math.exp(value) + 1 / Math.exp(value)) / 2;
const expm1Pf = (value: number) => Math.exp(value) - 1;
const tanhPf = (value: number) =>
  (Math.exp(2 * value) - 1) / (Math.exp(2 * value) + 1);
const log1pPf = (value: number) => Math.log(1 + value);

/**
 * @see https://gitlab.torproject.org/legacy/trac/-/issues/13018
 * @see https://bugzilla.mozilla.org/show_bug.cgi?id=531915
 */
export function getMathFingerprint(): Record<string, number> {
  // Note: constant values are empirical
  return {
    acos: acos(0.123124234234234242),
    acosh: acosh(1e308),
    acoshPf: acoshPf(1e154), // 1e308 will not work for polyfill
    asin: asin(0.123124234234234242),
    asinh: asinh(1),
    asinhPf: asinhPf(1),
    atanh: atanh(0.5),
    atanhPf: atanhPf(0.5),
    atan: atan(0.5),
    sin: sin(-1e300),
    sinh: sinh(1),
    sinhPf: sinhPf(1),
    cos: cos(10.000000000123),
    cosh: cosh(1),
    coshPf: coshPf(1),
    tan: tan(-1e300),
    tanh: tanh(1),
    tanhPf: tanhPf(1),
    exp: exp(1),
    expm1: expm1(1),
    expm1Pf: expm1Pf(1),
    log1p: log1p(10),
    log1pPf: log1pPf(10),
    powPI: Math.pow(Math.PI, -100),
  };
}
