export interface WebGLInfo {
  vendor: string;
  renderer: string;
}

export function getWebGLInfo(): WebGLInfo {
  const canvas = document.createElement("canvas");
  const gl = canvas.getContext("webgl");
  const rendererInfo = gl?.getExtension("WEBGL_debug_renderer_info");

  if (!rendererInfo || !gl) {
    return { vendor: "", renderer: "" };
  }

  return {
    vendor: gl.getParameter(rendererInfo.UNMASKED_VENDOR_WEBGL),
    renderer: gl.getParameter(rendererInfo.UNMASKED_RENDERER_WEBGL),
  };
}
