import * as cookies from "browser-cookies";
import { v4 as uuid } from "uuid";
import { getEtag } from "./etag";
import { hash } from "./hash";

const key = "__va";

function createVisitorId() {
  const id = uuid();
  const tag = getEtag();

  return hash(`${tag}.${id}`);
}

export function getVisitorId(): string {
  const cookieVa = cookies.get(key);
  const localStorageVa = localStorage.getItem(key);

  let vaValue = cookieVa || localStorageVa;
  if (vaValue && vaValue.length !== 32) {
    vaValue = "";
  }

  const id = vaValue || createVisitorId();

  // ensure/update the va value
  cookies.set(key, id, { expires: 365 });
  if (vaValue !== localStorageVa) {
    localStorage.setItem(key, id);
  }

  return id;
}
