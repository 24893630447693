import { isLandscape } from "./mediaMatches";

export function getScreenDiff() {
  const { availHeight, availWidth, width, height } = window.screen;
  return { heightDiff: height - availHeight, widthDiff: width - availWidth };
}

export type WindowWithDimentions = {
  devicePixelRatio: Window["devicePixelRatio"];
  screen: Pick<Window["screen"], "width" | "height">;
};

export function getScreenDimentions(win: WindowWithDimentions = window) {
  const pixelRatio = win.devicePixelRatio || 1;
  let { width, height } = win.screen;
  if (!isLandscape()) {
    const w = width;
    width = height;
    height = w;
  }
  return { width: width * pixelRatio, height: height * pixelRatio };
}
