import * as murmur from "murmurhash3js";

export function hash(payload: any): string {
  if (typeof payload !== "string") {
    payload = JSON.stringify(payload);
  }
  return murmur.x64.hash128(payload);
}

export function createVerifier({
  browserHash,
  visitorId,
  baseName,
  type,
}: {
  browserHash?: string | number;
  visitorId?: string | number;
  baseName?: string | number;
  type?: string;
}) {
  const input = [
    browserHash,
    visitorId,
    type && type !== "load" ? type : undefined,
  ]
    .filter((s) => s !== undefined)
    .join(",");
  return murmur.x86.hash32(input, baseName || 0);
}
