import { getCanvasFingerprint } from "./canvas";
import { getMathFingerprint } from "./math";
import { getTimezone } from "./timezone";
import { getHardwareConcurrency } from "./hardwareConcurrency";
import { getDeviceMemory } from "./deviceMemory";
import { getWebGLInfo } from "./webgl";
import { getVendorFlavors } from "./vendorFlavors";
import { getLanguages } from "./languages";
import { getMediaMatches } from "./mediaMatches";
import {
  getSupportedAudioTypes,
  getSupportedVideoTypes,
} from "./supportedMediaTypes";
import { getScreenDiff, getScreenDimentions } from "./screen";
import { getNativeNavigatorFunctions } from "./nativeNavigatorFunctions";
import { getPermissions } from "./permissions";
import { getVendor } from "./vendor";
import { getUserAgent } from "./user-agent";

export type Fingerprint = Awaited<ReturnType<typeof createFingerprint>>;

export const noop = <T extends Array<any>, U>(fn: (...args: T) => U) => fn;

export type Wrapper = typeof noop;

async function _create(wrap: Wrapper = noop) {
  const { widthDiff, heightDiff } = wrap(getScreenDiff)();
  const { height, width } = wrap(getScreenDimentions)();

  const [permissions, canvas] = await Promise.all([
    wrap(getPermissions)(),
    wrap(getCanvasFingerprint)(),
  ]);

  return {
    canvas,
    math: wrap(getMathFingerprint)(),
    timezone: wrap(getTimezone)(),
    hardwareConcurrency: wrap(getHardwareConcurrency)(),
    deviceMemory: wrap(getDeviceMemory)(),
    webglInfo: wrap(getWebGLInfo)(),
    vendorFlavors: wrap(getVendorFlavors)(),
    languages: wrap(getLanguages)(),
    mediaMatches: wrap(getMediaMatches)(),
    supportedAudioTypes: wrap(getSupportedAudioTypes)(),
    supportedVideoTypes: wrap(getSupportedVideoTypes)(),
    screenWidthDiff: widthDiff,
    screenHeightDiff: heightDiff,
    computedHeight: height,
    computedWidth: width,
    nativeNavigatorFunctions: wrap(getNativeNavigatorFunctions)(),
    vendor: wrap(getVendor)(),
    permissions,
    userAgent: wrap(getUserAgent)(),
  };
}

export async function createFingerprint(
  wrap: Wrapper = noop,
  hash?: (payload: any) => string
) {
  const fp = await _create(wrap);

  return {
    ...fp,
    browserHash: hash ? hash(fp) : "",
  };
}
