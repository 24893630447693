function computeBasePath() {
  const script = document.getElementById("pingme") as
    | HTMLScriptElement
    | undefined;
  if (!script || !script.src) {
    //bail out
    return "";
  }

  const split = script.src.split("/");

  return `${split[0]}//${split[2]}`;
}

export function computePath(...segments: string[]): string {
  if (segments && segments.length > 1) {
    const path = segments
      .filter(Boolean)
      .map((segments) => segments.replace(/^\/|\/$/g, ""))
      .join("/");
    return computePath(path);
  }

  const basePath = computeBasePath();

  let path = segments[0];
  if (!path) {
    return basePath;
  }

  if (path.indexOf("/") === 0) {
    path = path.substring(1);
  }

  return `${basePath}/${path}`;
}
